<template>
  <router-view class="content"></router-view>
</template>

<script>
export default {
  name: "CatalogueMainComponent"
};
</script>

<style></style>
